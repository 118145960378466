export type TicketStatus = "coming_soon" | "on_sale" | "sold_out";

export interface TicketConfig {
  status: TicketStatus;
  image: string;
  link?: string;
  name: string;
  time: string;
}

export interface TicketsConfig {
  [key: string]: TicketConfig;
}

export const TICKETS_CONFIG: TicketsConfig = {
  hoa_anh_dao: {
    name: "HOA ANH ĐÀO",
    status: "on_sale",
    image: "ve_hoa_anh_dao_1", // Sẽ được map với images trong component
    link: "https://cticket.vn/en/event/dalatchill-bomuayeu-1511-281231",
    time: "2024-11-16 21:00:00",
  },
  phuong_tim: {
    name: "PHƯỢNG TÍM",
    status: "on_sale",
    image: "ve_phuong_tim_0",
    link: "https://cticket.vn/en/event/dalatchill-bomuayeu-1511-281231",
    time: "2024-11-23 21:00:00",
  },
  fanzone: {
    name: "FANZONE",
    status: "on_sale",
    image: "ve_fanzone_2",
    link: "https://cticket.vn/en/event/dalatchill-bomuayeu-1511-281231",
    time: "2024-11-23 21:00:00",
  },
  mimosa: {
    name: "MIMOSA",
    status: "on_sale",
    image: "ve_mimosa_0",
    link: "https://cticket.vn/en/event/dalatchill-bomuayeu-1511-281231",
    time: "2024-11-30 21:00:00",
  },
  da_quy: {
    name: "DA QUÝ",
    status: "on_sale",
    image: "ve_da_quy_0",
    link: "https://cticket.vn/en/event/dalatchill-bomuayeu-1511-281231",
    time: "2024-11-30 21:00:00",
  },
};
