import { ValuePropsGetUser } from "../../utils/interface.global";
import { URL_API } from "../constants/api";
import Api from "./api";

export const loginUserByAPI = async (props: ValuePropsGetUser) => {
  const reqUrl = URL_API.LOGIN;

  const res = await Api.post(reqUrl, props);
  console.log("res", res.data);
  return res.data;
};

export const loadDataUserByAPI = async (props: any) => {
  const reqUrl = URL_API.LOGIN;

  const res = await Api.post(reqUrl, props);
  //console.log("res", res.data);
  return res.data;
};

export const getLuckydrawByAPI = async (props: any) => {
  const reqUrl = URL_API.LUCKYDRAW;

  const res = await Api.post(reqUrl, props);
  //console.log("res", res.data);
  return res.data;
};

export const postRegisterAPI = async (props: any) => {
  const reqUrl = URL_API.REGISTER;

  const res = await Api.post(reqUrl, props);
  //console.log("res", res.data);
  return res.data;
};

export const postGetConfigAPI = async (props: any) => {
  const reqUrl = URL_API.GET_CONFIG;

  const res = await Api.post(reqUrl, props);
  //console.log("res", res.data);
  return res.data;
};

export const postCheckPaymentAPI = async (props: any) => {
  const reqUrl = URL_API.CHECK_PAYMENT;

  const res = await Api.post(reqUrl, props);
  //console.log("res", res.data);
  return res.data;
};
