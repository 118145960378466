import React, { useState, useRef } from "react";
import video_teaser from "../assets/video_teaser.png";

const VideoModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const openModal = () => {
    setIsOpen(true);
    // Play video when modal opens
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    }, 100);
  };

  const closeModal = () => {
    setIsOpen(false);
    // Pause video when modal closes
    if (videoRef.current) {
      videoRef.current.pause();
      // Reset video về thời điểm bắt đầu
      videoRef.current.currentTime = 0;
    }
  };

  const handleVideoEnded = () => {
    closeModal();
  };

  return (
    <div className="relative overflow-hidden">
      {/* Thumbnail and play button */}
      <div className="relative cursor-pointer" onClick={openModal}>
        <img
          loading="lazy"
          src={video_teaser}
          className="object-cover w-full"
          alt="Video teaser thumbnail"
        />
        <button className="font-normal text-6xl max-md:text-base absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-transparent text-white hover:text-yellow-400 transition-colors duration-300 w-full">
          VIDEO TEASER
        </button>
      </div>

      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="relative w-full max-w-[1012px] px-16 pb-20 max-md:px-5 max-md:pt-8 max-md:pb-8 max-md:mt-2">
            {/* Video container with similar styling as thumbnail */}
            <div className="relative w-full">
              {/* Close button */}
              <button
                onClick={closeModal}
                className="absolute -top-10 right-0 text-white hover:text-yellow-400 text-xl z-50"
              >
                ✕
              </button>

              {/* Video with border styling */}
              <div className="relative">
                <div className="relative bg-[#1F1539] rounded-3xl overflow-hidden rounded-3xl border-[3px] border-[#6756F1] border-opacity-50">
                  <video
                    ref={videoRef}
                    className="w-full h-full object-cover"
                    controls
                    autoPlay
                    onEnded={handleVideoEnded}
                  >
                    <source src="/4-MUA-YEU-TRAILER.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoModal;
