import { useEffect, useState } from "react";
import { TicketConfig } from "../configs/ticketConfig";

interface TicketItemProps {
  config: TicketConfig;
  images: {
    coming_soon: string;
    on_sale: string;
    sold_out: string;
  };
  className?: string; // Thêm className prop
}

const TicketItem = ({ config, images, className = "" }: TicketItemProps) => {
  const [status, setStatus] = useState("");
  useEffect(() => {
    const currentTime = new Date();
    const openTime = new Date(config.time);

    if (currentTime.getTime() < openTime.getTime()) {
      setStatus("coming_soon");
    } else {
      setStatus(config.status);
    }
  }, [config]);

  const getImage = () => {
    switch (status) {
      case "coming_soon":
        return images.coming_soon;
      case "on_sale":
        return images.on_sale;
      case "sold_out":
        return images.sold_out;
      default:
        return images.coming_soon;
    }
  };

  // Base classes cho img
  const baseImageClass = `object-contain ${
    status === "sold_out" ? "cursor-not-allowed" : ""
  } ${className}`;

  if (status === "on_sale" && config.link) {
    return (
      <a
        href={config.link}
        target="_blank"
        rel="noopener noreferrer"
        className="cursor-pointer"
      >
        <img
          loading="lazy"
          srcSet={getImage()}
          className={baseImageClass}
          alt={config.name}
        />
      </a>
    );
  }

  return (
    <img
      loading="lazy"
      srcSet={getImage()}
      className={baseImageClass}
      alt={config.name}
    />
  );
};

export default TicketItem;
