import { useState } from "react";
import "./App.css";
import Home from "./pages/Home";
import ProtectedRouter from "./auth/ProtectedRouter";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import DataContext from "./context/DataContext";
import "react-toastify/dist/ReactToastify.css";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}

const router = createBrowserRouter([
  {
    path: "/*",
    element: <ProtectedRouter />,
    children: [{ path: "", element: <Home /> }],
  },
]);

function App() {
  const [user, setUser] = useState(null);

  return (
    <DataContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      <div className="font-unbounded w-full h-full min-h-screen bg-gradient-custom">
        <RouterProvider router={router} />
      </div>
    </DataContext.Provider>
  );
}

export default App;
