import { Fragment, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

export default function Root() {
  return (
    <>
      <div className="w-full mx-auto min-h-screen bg-cover bg-center">
        <Outlet />
      </div>
    </>
  );
}
