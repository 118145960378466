import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import AOS from "aos";
import "aos/dist/aos.css";

import header_bg from "../assets/header_bg.jpg";
import header_mobile from "../assets/header_mobile.png";

import header_img from "../assets/header_img.png";
import header_logo from "../assets/header_logo.png";
import logo_2 from "../assets/logo_2.png";
import so_do from "../assets/so_do.png";
import icon_address from "../assets/icon_address.png";

import ve_hoa_anh_dao_0 from "../assets/ve_hoa_anh_dao_0.png";
import ve_hoa_anh_dao_1 from "../assets/ve_hoa_anh_dao_1.png";
import ve_hoa_anh_dao_2 from "../assets/ve_hoa_anh_dao_2.png";
import ve_phuong_tim_0 from "../assets/ve_phuong_tim_0.png";
import ve_phuong_tim_1 from "../assets/ve_phuong_tim_1.png";
import ve_phuong_tim_2 from "../assets/ve_phuong_tim_2.png";
import ve_fanzone_0 from "../assets/ve_fanzone_0.png";
import ve_fanzone_1 from "../assets/ve_fanzone_1.png";
import ve_fanzone_2 from "../assets/ve_fanzone_2.png";
import ve_mimosa_0 from "../assets/ve_mimosa_0.png";
import ve_mimosa_1 from "../assets/ve_mimosa_1.png";
import ve_mimosa_2 from "../assets/ve_mimosa_2.png";
import ve_da_quy_0 from "../assets/ve_da_quy_0.png";
import ve_da_quy_1 from "../assets/ve_da_quy_1.png";
import ve_da_quy_2 from "../assets/ve_da_quy_2.png";

import logo_partner_1 from "../assets/logo_partner_1.png";
import logo_partner_2 from "../assets/logo_partner_2.png";
import logo_partner_3 from "../assets/logo_partner_3.png";
import icon_insta from "../assets/icon-insta.svg";
import icon_facebook from "../assets/icon_facebook.svg";
import icon_tiktok from "../assets/icon_tiktok.svg";

import navi_left from "../assets/navi_left.svg";
import navi_right from "../assets/navi_right.svg";
import ca_si_1_0 from "../assets/ca_si_1_0.png";
import ca_si_1_1 from "../assets/ca_si_1_1.png";
import ca_si_2_0 from "../assets/ca_si_2_0.png";
import ca_si_2_1 from "../assets/ca_si_2_1.png";
import ca_si_3_0 from "../assets/ca_si_3_0.png";
import ca_si_3_1 from "../assets/ca_si_3_1.png";
import ca_si_4_0 from "../assets/ca_si_4_0.png";
import ca_si_4_1 from "../assets/ca_si_4_1.png";
import ca_si_5_0 from "../assets/ca_si_5_0.png";
import ca_si_5_1 from "../assets/ca_si_5_1.png";
import ca_si_6_0 from "../assets/ca_si_6_0.png";
import ca_si_6_1 from "../assets/ca_si_6_1.png";
import ca_si_7_0 from "../assets/ca_si_7_0.png";
import ca_si_7_1 from "../assets/ca_si_7_1.png";
import ca_si_8_0 from "../assets/ca_si_8_0.png";
import ca_si_8_1 from "../assets/ca_si_8_1.png";
import ca_si_9_0 from "../assets/ca_si_9_0.png";
import ca_si_9_1 from "../assets/ca_si_9_1.png";
import ca_si_10_0 from "../assets/ca_si_10_0.png";
import ca_si_10_1 from "../assets/ca_si_10_1.png";
import { TICKETS_CONFIG } from "../configs/ticketConfig";
import TicketItem from "../components/TicketItem";
import VideoModal from "../components/VideoModal";

const list_casi = [
  {
    id: 3,
    name: "Mỹ Tâm",
    img_0: ca_si_3_0,
    img_1: ca_si_3_1,
    time: "2024-11-15 20:00:00",
  },
  {
    id: 2,
    name: "ISAAC",
    img_0: ca_si_2_0,
    img_1: ca_si_2_1,
    time: "2024-11-15 20:00:00",
  },
  {
    id: 1,
    name: "Lou Hoàng",
    img_0: ca_si_1_0,
    img_1: ca_si_1_1,
    time: "2024-11-14 00:00:00",
  },
  {
    id: 4,
    name: "Kay Trần",
    img_0: ca_si_4_0,
    img_1: ca_si_4_1,
    time: "2024-11-17 20:00:00",
  },
  {
    id: 6,
    name: "Tăng Phúc",
    img_0: ca_si_6_0,
    img_1: ca_si_6_1,
    time: "2024-11-19 20:00:00",
  },
  {
    id: 10,
    name: "Chillies Band",
    img_0: ca_si_10_0,
    img_1: ca_si_10_1,
    time: "2024-11-20 20:00:00",
  },
  {
    id: 5,
    name: "LyLy",
    img_0: ca_si_5_0,
    img_1: ca_si_5_1,
    time: "2024-11-21 20:00:00",
  },
  {
    id: 9,
    name: "DJ Mie",
    img_0: ca_si_9_0,
    img_1: ca_si_9_1,
    time: "2024-11-22 20:00:00",
  },
  {
    id: 7,
    name: "DJ VA",
    img_0: ca_si_7_0,
    img_1: ca_si_7_1,
    time: "2024-11-25 20:00:00",
  },
  {
    id: 8,
    name: "MC HYPE JBoo",
    img_0: ca_si_8_0,
    img_1: ca_si_8_1,
    time: "2024-11-27 12:00:00",
  },
];
function Home() {
  const premiumRef = useRef<HTMLDivElement | null>(null);

  const scrollToPremium = () => {
    if (premiumRef.current) {
      premiumRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // Đặt thời gian kết thúc với múi giờ GMT+7
    const endTime = new Date("2024-12-28T00:00:00+07:00").getTime();

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = endTime - now;

      // Tính toán ngày, giờ, phút, giây
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        // Nếu đã qua thời gian
        clearInterval(timer);
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      } else {
        setTimeLeft({
          days,
          hours,
          minutes,
          seconds,
        });
      }
    }, 1000);

    // Cleanup khi component unmount
    return () => clearInterval(timer);
  }, []);

  const ticketImages = {
    hoa_anh_dao: {
      coming_soon: ve_hoa_anh_dao_0,
      on_sale: ve_hoa_anh_dao_1,
      sold_out: ve_hoa_anh_dao_2,
    },
    phuong_tim: {
      coming_soon: ve_phuong_tim_0,
      on_sale: ve_phuong_tim_1,
      sold_out: ve_phuong_tim_2,
    },
    fanzone: {
      coming_soon: ve_fanzone_0,
      on_sale: ve_fanzone_1,
      sold_out: ve_fanzone_2,
    },
    mimosa: {
      coming_soon: ve_mimosa_0,
      on_sale: ve_mimosa_1,
      sold_out: ve_mimosa_2,
    },
    da_quy: {
      coming_soon: ve_da_quy_0,
      on_sale: ve_da_quy_1,
      sold_out: ve_da_quy_2,
    },
  };

  // Khởi tạo AOS
  useEffect(() => {
    AOS.init({
      duration: 1000, // thời gian animation
      once: false, // animation sẽ lặp lại khi scroll lên xuống
      mirror: true, // elements sẽ animate khi scroll lên
      offset: 50, // offset (px) từ trigger point
    });
  }, []);

  const getLinkCaSi = (casi: any) => {
    const currentTime = new Date();
    const casiTime = new Date(casi.time);

    return currentTime > casiTime ? casi.img_1 : casi.img_0;
  };

  return (
    <div
      className="relative flex flex-col justify-center items-center pb-2 bg-black bg-contain bg-no-repeat bg-top w-full"
      style={{ backgroundImage: `url(${header_bg})` }}
    >
      <div className="flex overflow-hidden flex-col w-full">
        <div className="flex relative flex-col w-full max-md:max-w-full">
          <div className="relative max-w-full w-[80%] mx-auto flex z-20 block max-sm:hidden">
            <div className="w-1/2">
              <img
                loading="lazy"
                srcSet={header_img}
                className="object-contain w-full max-md:mt-10"
              />
            </div>
            <div className="w-1/2 flex flex-col">
              <div className="">
                <img
                  loading="lazy"
                  srcSet={header_logo}
                  className="object-contain w-full max-md:mt-10"
                />
              </div>
              <div className="flex flex-col w-full">
                <div className="flex relative gap-0 items-start text-center">
                  <div className="flex flex-col justify-center items-center min-w-[240px] max-md:max-w-full">
                    <div className="flex flex-col items-center max-md:max-w-full">
                      <div className="flex flex-wrap gap-5 md:gap-3 items-center px-7 md:px-3 py-4 whitespace-nowrap border-2 border-indigo-500 border-solid shadow-sm rounded-[40px] max-md:px-5 max-md:max-w-full">
                        <div className="flex flex-col self-stretch my-auto w-[68px] md:w-[45px]">
                          <div className="text-4xl md:text-lg font-bold text-fuchsia-300">
                            {timeLeft.days.toString().padStart(2, "0")}
                          </div>
                          <div className="text-lg md:text-sm text-[#7F70FF]">
                            day
                          </div>
                        </div>
                        <div className="self-stretch w-7 text-3xl text-violet-500">
                          :
                        </div>
                        <div className="flex flex-col self-stretch my-auto w-[68px] md:w-[45px]">
                          <div className="text-4xl md:text-lg font-bold text-violet-500">
                            {timeLeft.hours.toString().padStart(2, "0")}
                          </div>
                          <div className="text-lg md:text-sm text-[#7F70FF]">
                            hours
                          </div>
                        </div>
                        <div className="self-stretch w-7 text-3xl text-violet-500">
                          :
                        </div>
                        <div className="flex flex-col self-stretch my-auto w-[68px] md:w-[45px]">
                          <div className="text-4xl md:text-lg font-bold text-violet-500">
                            {timeLeft.minutes.toString().padStart(2, "0")}
                          </div>
                          <div className="text-lg md:text-sm text-[#7F70FF]">
                            mins
                          </div>
                        </div>
                        <div className="self-stretch w-7 text-3xl text-violet-500">
                          :
                        </div>
                        <div className="flex flex-col self-stretch my-auto w-[68px] md:w-[45px]">
                          <div className="text-4xl md:text-lg font-bold text-violet-500">
                            {timeLeft.seconds.toString().padStart(2, "0")}
                          </div>
                          <div className="text-lg md:text-sm text-[#7F70FF]">
                            secs
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={scrollToPremium}
                        className="cursor-pointer font-normal overflow-hidden gap-2.5 self-center px-5 py-6 mt-14 max-w-full text-2xl text-white min-h-[80px] rounded-[40px] shadow-[4px_8px_16px_rgba(90,72,243,0.6)] [background:linear-gradient(180deg,rgb(101.63,214.1,255)_0%,rgb(102.64,86.43,241.19)_100%)] w-[367px] hover:text-[#FFB1EA] transition-colors duration-300
  max-md:mt-8 max-md:w-[200px] max-md:px-2 max-md:py-2 max-md:text-lg max-md:min-h-[60px]"
                      >
                        MUA VÉ NGAY
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full hidden max-sm:block z-20">
            <img
              loading="lazy"
              srcSet={header_mobile}
              className="object-contain w-full"
            />
            <div className="font-normal text-center text-white max-md:max-w-full text-base mt-2">
              Quảng Trường Lâm Viên
            </div>
            <div className="font-light text-center text-white max-md:max-w-full text-[0.55rem] mt-1">
              (Đường Trần Quốc Toản, Phường 1, Đà Lạt, Lâm Đồng)
            </div>
          </div>
          <div className="flex w-full hidden max-sm:block z-20 mt-3">
            <div className="flex flex-col items-center">
              {/* Countdown container */}
              <div className="flex items-center justify-center px-4 py-3 whitespace-nowrap border-2 border-indigo-500 border-solid shadow-sm rounded-[40px]">
                {/* Days */}
                <div className="flex flex-col items-center w-[45px]">
                  <div className="text-lg font-bold text-fuchsia-300">
                    {timeLeft.days.toString().padStart(2, "0")}
                  </div>
                  <div className="text-xs text-[#7F70FF]">day</div>
                </div>
                <div className="text-lg text-violet-500 mx-1">:</div>

                {/* Hours */}
                <div className="flex flex-col items-center w-[45px]">
                  <div className="text-lg font-bold text-violet-500">
                    {timeLeft.hours.toString().padStart(2, "0")}
                  </div>
                  <div className="text-xs text-[#7F70FF]">hours</div>
                </div>
                <div className="text-lg text-violet-500 mx-1">:</div>

                {/* Minutes */}
                <div className="flex flex-col items-center w-[45px]">
                  <div className="text-lg font-bold text-violet-500">
                    {timeLeft.minutes.toString().padStart(2, "0")}
                  </div>
                  <div className="text-xs text-[#7F70FF]">mins</div>
                </div>
                <div className="text-lg text-violet-500 mx-1">:</div>

                {/* Seconds */}
                <div className="flex flex-col items-center w-[45px]">
                  <div className="text-lg font-bold text-violet-500">
                    {timeLeft.seconds.toString().padStart(2, "0")}
                  </div>
                  <div className="text-xs text-[#7F70FF]">secs</div>
                </div>
              </div>

              <div
                onClick={scrollToPremium}
                className="text-center cursor-pointer font-normal overflow-hidden gap-2.5 self-center px-5 py-6 mt-14 max-w-full text-2xl text-white min-h-[80px] rounded-[40px] shadow-[4px_8px_16px_rgba(90,72,243,0.6)] [background:linear-gradient(180deg,rgb(101.63,214.1,255)_0%,rgb(102.64,86.43,241.19)_100%)] w-[367px] hover:text-[#FFB1EA] transition-colors duration-300
  max-md:mt-8 max-md:w-[200px] max-md:px-2 max-md:py-2 max-md:text-base max-md:min-h-[40px]"
              >
                MUA VÉ NGAY
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="relative overflow-hidden px-16 pb-20 w-full text-6xl font-medium leading-none text-center text-white capitalize max-w-[1012px] max-md:px-5 max-md:pt-8 max-md:pb-8 max-md:mt-2 max-md:max-w-full max-md:text-4xl"
      >
        <VideoModal />
      </div>
      <div data-aos="fade-up" className="px-12 max-md:px-5 w-full">
        <div className="overflow-hidden w-full">
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={12}
            slidesPerView={5}
            navigation={{
              prevEl: ".custom-prev",
              nextEl: ".custom-next",
            }}
            pagination={{ clickable: true }}
            breakpoints={{
              768: {
                slidesPerView: 5,
                spaceBetween: 12,
                width: null,
              },
              0: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                grid: {
                  rows: 3,
                  fill: "row",
                },
                spaceBetween: 10,
                width: null,
              },
            }}
            className="w-full relative"
          >
            <div className="absolute left-0 top-1/2 z-10 -translate-y-1/2 custom-prev cursor-pointer max-md:w-[40px]">
              <img src={navi_left} alt="Previous" />
            </div>
            <div className="absolute right-0 top-1/2 z-10 -translate-y-1/2 custom-next cursor-pointer max-md:w-[40px]">
              <img src={navi_right} alt="Next" />
            </div>
            {list_casi.map((casi, index) => (
              <SwiperSlide key={index}>
                <img
                  loading="lazy"
                  src={getLinkCaSi(casi)}
                  className="w-full h-auto object-cover"
                  alt={casi.name}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="flex flex-col mt-32 w-full text-center text-white max-w-[1096px] max-md:mt-10 max-md:max-w-full"
      >
        <div className="flex flex-col w-full text-xl leading-8 max-md:max-w-full max-md:px-5 md:max-lg:px-8">
          <img
            loading="lazy"
            srcSet={logo_2}
            className="object-contain self-center max-w-full w-[542px]"
          />
          <div className="mt-5 max-md:max-w-full font-light text-base max-md:text-xs text-justify">
            Đà Lạt, thành phố của hoa và sương mờ, là điểm dừng chân của những
            tâm hồn yêu thích sự lãng mạn. Trong không gian ấy, tình yêu như
            dòng suối dịu dàng chảy qua từng mùa, mỗi mùa lại mang theo một sắc
            thái cảm xúc khác nhau. Chương trình âm nhạc này sẽ là hành trình
            đưa khán giả đi qua từng sắc màu của tình yêu Đà Lạt. Để kết hợp với
            chuỗi sự kiện Festival hoa Đà Lạt, một sự kiện âm nhạc đặc trưng ở
            xứ sở sương mù với chủ đề “Hoa Đà Lạt - Bản giao hưởng sắc màu”,
            Festival Hoa Đà Lạt lần thứ 10 năm 2024 dự kiến sẽ được tổ chức vào
            tháng 12 tại thành phố Đà Lạt.
            <br />
            <br />
            Đây là một hành trình cảm xúc, khi chúng ta được đắm chìm trong
            những giai điệu ngọt ngào và tươi trẻ, hòa quyện với vẻ đẹp thiên
            nhiên của Đà Lạt qua bốn mùa. Hãy lắng nghe chúng tôi kể những câu
            chuyện &quot;YÊU&quot; qua mỗi mùa hoa ở Đà Lạt. Với kết hợp tinh tế
            giữa âm nhạc, cảm xúc và những yếu tố mùa trong sân khấu, tạo nên
            một nghệ thuật đa chiều, giúp khán giả không chỉ trải nghiệm tình
            yêu qua bốn mùa của thành phố mà còn cảm nhận sự kết nối giữa những
            thế hệ, giữa những giai đoạn khác nhau trong cuộc đời mỗi người. Một
            đêm nhạc không chỉ để thưởng thức mà còn để sẻ chia và hồi tưởng.
          </div>
        </div>
        <div
          onClick={scrollToPremium}
          className="cursor-pointer font-normal overflow-hidden gap-2.5 self-center px-5 py-6 mt-14 max-w-full text-2xl text-white min-h-[80px] rounded-[40px] shadow-[4px_8px_16px_rgba(90,72,243,0.6)] [background:linear-gradient(180deg,rgb(101.63,214.1,255)_0%,rgb(102.64,86.43,241.19)_100%)] w-[367px] hover:text-[#FFB1EA] transition-colors duration-300
  max-md:mt-8 max-md:w-[200px] max-md:px-2 max-md:py-2 max-md:text-base max-md:min-h-[40px]"
        >
          MUA VÉ NGAY
        </div>
      </div>
      <img
        data-aos="zoom-in"
        loading="lazy"
        srcSet={so_do}
        className="object-contain self-stretch mt-32 w-full max-md:mt-10 max-md:max-w-full"
      />
      <div
        data-aos="fade-up"
        ref={premiumRef}
        className="flex flex-col mt-32 w-full max-w-[1238px] max-sm:mt-10"
      >
        {/* Vé Premium */}
        <div
          data-aos="fade-up"
          className="flex flex-wrap items-center self-center w-[calc(50%-20px)] mx-auto"
        >
          <TicketItem
            data-aos="fade-up"
            className="object-contain grow shrink self-stretch my-auto aspect-[1.93] w-full"
            config={TICKETS_CONFIG.hoa_anh_dao}
            images={ticketImages.hoa_anh_dao}
          />
        </div>

        {/* Các vé khác */}
        <div className="flex flex-col mt-32 w-full max-sm:mt-10 max-sm:px-5 md:max-lg:px-8">
          {/* Hàng 1 */}
          <div className="flex flex-wrap gap-10 items-center w-full">
            <div data-aos="fade-up" className="w-[calc(50%-20px)]">
              <TicketItem
                className="object-contain grow shrink self-stretch my-auto aspect-[1.93] w-full"
                config={TICKETS_CONFIG.phuong_tim}
                images={ticketImages.phuong_tim}
              />
            </div>
            <div data-aos="fade-up" className="w-[calc(50%-20px)]">
              <TicketItem
                className="object-contain grow shrink self-stretch my-auto aspect-[2] w-full"
                config={TICKETS_CONFIG.fanzone}
                images={ticketImages.fanzone}
              />
            </div>
          </div>

          {/* Hàng 2 */}
          <div className="flex flex-wrap gap-10 items-center mt-14 w-full max-sm:mt-10">
            <div data-aos="fade-up" className="w-[calc(50%-20px)]">
              <TicketItem
                className="object-contain grow shrink self-stretch my-auto aspect-[2] w-full"
                config={TICKETS_CONFIG.mimosa}
                images={ticketImages.mimosa}
              />
            </div>
            <div data-aos="fade-up" className="w-[calc(50%-20px)]">
              <TicketItem
                className="object-contain grow shrink self-stretch my-auto aspect-[1.93] w-full"
                config={TICKETS_CONFIG.da_quy}
                images={ticketImages.da_quy}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="flex flex-col mt-32 w-full text-4xl font-medium uppercase max-w-[1208px] max-md:mt-10 max-md:max-w-full max-md:px-5 md:max-lg:px-8"
      >
        <div className="font-bold text-center text-white max-md:max-w-full text-4xl max-md:text-base">
          Hoặc mua vé trực tiếp tại
        </div>
        <div className="flex flex-col items-center p-8 mt-12 w-full text-violet-300 rounded-3xl max-md:px-5 max-md:mt-10 max-md:max-w-full [background:linear-gradient(130deg,#6756F1_0%,#171145_100%)]">
          <div className="font-medium text-4xl max-md:text-lg">
            Kimochi coffee
          </div>
          <div className="flex items-start mt-5 text-white capitalize max-md:max-w-full">
            <img
              loading="lazy"
              src={icon_address}
              className="object-contain shrink-0 w-6 aspect-square min-w-[24px]"
            />
            <div className="ml-2 font-normal text-xl max-md:text-xs">
              2/4, Trần Quang Diệu, Phường 10, Thành phố Đà Lạt, Tỉnh Lâm Đồng
            </div>
          </div>
          <div className="mt-5 font-medium text-4xl max-md:text-lg">
            ĐÀ LẠT CHILL COFFEE
          </div>
          <div className="flex items-start mt-5 text-white capitalize max-md:max-w-full">
            <img
              loading="lazy"
              src={icon_address}
              className="object-contain shrink-0 w-6 aspect-square min-w-[24px]"
            />
            <div className="ml-2 font-normal text-xl max-md:text-xs">
              298 Đường Phù Đổng Thiên Vương, Phường 8, Thành phố Đà Lạt, Tỉnh
              Lâm Đồng
            </div>
          </div>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="flex flex-col mt-32 max-w-full w-[658px] max-md:mt-10"
      >
        <div className="font-medium text-4xl max-md:text-base text-center text-white uppercase max-md:max-w-full">
          ĐỐI TÁC - NHÀ TÀI TRỢ
        </div>
        <div className="flex gap-10 justify-center items-center self-center mt-16 max-md:mt-10 max-md:gap-4 max-md:px-4">
          <a
            href="https://www.facebook.com/@cticket.cake.vn/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              loading="lazy"
              srcSet={logo_partner_1}
              className="object-contain shrink-0 self-stretch my-auto aspect-[4.93] w-[182px] hover:-translate-y-2 transition-transform duration-300 ease-in-out max-md:w-[120px]"
            />
          </a>
          {/* <a
            href="https://www.facebook.com/@XanhSMOfficial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              loading="lazy"
              srcSet={logo_partner_2}
              className="object-contain shrink-0 self-stretch my-auto w-52 aspect-[3.47] hover:-translate-y-2 transition-transform duration-300 ease-in-out max-md:w-[100px]"
            />
          </a> */}
          <a
            href="https://www.facebook.com/begroupvn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              loading="lazy"
              srcSet={logo_partner_3}
              className="object-contain shrink-0 self-stretch my-auto aspect-[1.28] w-[92px] hover:-translate-y-2 transition-transform duration-300 ease-in-out max-md:w-[60px]"
            />
          </a>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="flex flex-wrap gap-10 justify-between items-center self-stretch px-24 py-10 mt-32 border-t border-gray-200 shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-5 max-md:mt-10 max-md:max-w-full max-md:flex-col max-md:gap-8"
      >
        {/* Logo */}
        <a href="/" className="cursor-pointer max-md:mx-auto">
          <img
            loading="lazy"
            srcSet={logo_2}
            className="object-contain shrink-0 self-stretch my-auto aspect-[2.93] min-w-[240px] w-[302px] hover:-translate-y-2 transition-transform duration-300 ease-in-out max-md:w-[80%] max-md:mx-auto"
          />
        </a>

        {/* Social Icons */}
        <div className="flex flex-col justify-center self-stretch my-auto max-md:w-full">
          <div className="flex gap-8 max-md:gap-5 items-start max-md:justify-center">
            <a
              href="https://www.instagram.com/bonmuayeu_/?fbclid=IwY2xjawGUPIhleHRuA2FlbQIxMAABHXJ-SkqBmSO_OIc5eWRG4oMmXDFHp9OPNazAm4jHtPP4sKH2dSd5-KNWQg_aem_Xqhh6YnnhJgogoQQ-RS_Ww"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                loading="lazy"
                src={icon_insta}
                className="object-contain shrink-0 w-12 aspect-square hover:-translate-y-2 transition-transform duration-300 ease-in-out max-md:w-8"
              />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61568224791671"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                loading="lazy"
                src={icon_facebook}
                className="object-contain shrink-0 w-12 aspect-square hover:-translate-y-2 transition-transform duration-300 ease-in-out max-md:w-8"
              />
            </a>
            <a
              href="https://www.tiktok.com/@4muayeu.concert?_t=8r5H9SE8Keo&_r=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                loading="lazy"
                src={icon_tiktok}
                className="object-contain shrink-0 w-12 aspect-square hover:-translate-y-2 transition-transform duration-300 ease-in-out max-md:w-8"
              />
            </a>
          </div>
        </div>

        {/* Contact Info */}
        <div className="self-stretch my-auto font-normal text-xl leading-8 text-right max-md:text-center text-white max-md:text-xs">
          Hotline/ CSKH (Vé) - 1900.636.686
          <br />
          Ban tổ chức - 0982.828.335
          <br />
          Liên hệ tài trợ - 0989.006.618
        </div>
      </div>
    </div>
  );
}
export default Home;
